.savebutton {
   
    min-width: 4.5rem;
}
.w-10{
 width: 10%;
}
.w-90{
    width: 90%;
}
.savecompletebutton{
    --tw-bg-opacity: 1;
    --tw-text-opacity: 1; 
    background-color: rgba(0, 155, 170, var(--tw-bg-opacity)) !important;
    border-color:rgba(133, 211, 216, var(--tw-bg-opacity)) !important;
    border-radius: 0.5rem;
    color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
    cursor: pointer;
    font-family: AvantGardeGothicITCW02Md, Myriad Pro, Myriad, Sans-serif;
    padding: 0.75rem 1rem;
    text-align: left;
}
.savecompletebutton:disabled{
   opacity: 0.5 !important;
}
.savebutton:disabled { 
    min-width: 4.5rem;
}