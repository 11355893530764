.memo-root-3,
.jss-3 {
    width: 1.5rem!important;
    height: 1.5rem!important;
}

.min-ht {
    min-height: 320px;
}

@media (max-width:414px) {
    .grWEmO {
        width: 3.5rem!important;
    }
    .cEWlxS {
        width: 3.5rem!important;
    }
}