.attachment {
  color: blue;
  cursor: pointer;
  margin-left: 5px;
  text-decoration: underline;

}


.ql-container {
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  /* background: #fefcfc; */
}

/* Snow Theme */
.app .ql-snow.ql-toolbar {
  display: block;
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

.app .ql-editor {
  min-height: 18em;
}

.ql-bold, .ql-italic, .ql-underline, .ql-strike, .ql-blockquote, .ql-list, .ql-indent, .ql-code-block, .ql-clean, .ql-color .ql-picker 
.ql-color-picker, .ql-link {
  min-width: 70px !important;
  min-height: 25px !important;
  
}

.loader{
  height: 750px !important;
}


.mediaHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 2rem;
  background-color: rgb(242, 242, 242);
  height: 2.5rem;
  flex-grow: 1;
}

.mediaHeaderName {
  flex-basis: 60%;
}

.tableHeader {
  padding-right: 2rem;
}


.tableRowColor {
background: #f2efeb;
}

.thumbnail {
/* height: 50%; */
width: 3rem !important;
height: 3rem !important;
padding: 13px;
margin-left: 10px;
}

.deleteBtn {
font-size: 1.2rem !important;
font-family: system-ui, -apple-system, Segoe UI, Roboto, Helvetica, Arial,
  sans-serif, Apple Color Emoji, Segoe UI Emoji;

font-weight: 500;
border-radius: 2rem;
font-style: initial;
color: #000;
text-align: center;
cursor: pointer;
}

.highLight{
color: red;
}

.imgName {
display: flex;
align-content: space-between;
align-items: center;
margin-left: -2rem;
}

.thumbnailImgName {
flex-shrink: 0;
word-wrap: break-word;
max-width: 80%;
/* inline-size: 50rem; */
}

.mediaRowContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 2rem;
  flex-grow: 1;
}


@media (min-width: 314px) and (max-width: 767px) {
.ql-picker-label{
  margin-left: -10px !important;
} 
.w-less{
  width: 99% !important;
}
}


