.hm-menu {
    background-color: transparent;
    min-width: 0px!important;
    border-radius: 50%!important;
    padding: 10px;
}

.hm-menu :hover {
    background-color: transparent;
}

.pl-nav {
    padding: 0.3rem!important;
}

.active-link {
    text-decoration-line: underline !important;
    color: rgba(0, 155, 170, 0.9) !important
}