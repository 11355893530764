.characterCount {
    padding-left: -1rem;
}

.editor-container {
    height: 15rem;
    padding: 1rem;
  }

.contentPosition {
    margin-left: -1rem;
}  

.w-alert {
    width: 99% !important
}


@media (min-width: 314px) and (max-width: 767px) {
    .w-alert {
        width: 96% !important
    }
    
}

@media (min-width: 768px) and (max-width: 820px) {
    .w-alert {
        width: 98% !important
    }    
}