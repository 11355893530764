.hideQuestion {
  display: none !important;
}
.unhideQuestion {
  display: block;
}

.div-wrap {
  word-wrap: break-word !important;
}

.div-word-break {
  word-break: break-word;
}

@media (max-width: 912px) {
  tr td {
    width: 20%!important;
  }
}