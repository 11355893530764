  @media (min-width: 375px) and (max-width: 820px) {
    .toolInfo {
        width: 30px !important;
        height: auto !important;;
    }
  }

  .a-blue{
    cursor: pointer;
  }

  .a-blue:hover{ 
    text-decoration: underline;
  }
  
  .toolInfo {
    height: 30px !important;
    width: 30px !important;
    margin-left: 0.6rem;
}

.HighRiseText{
  top: 15px !important;
  position: absolute;
}

.desc-pad {
    padding-left: 0px !important;
    word-wrap: break-word !important;
    z-index: 1 !important;
}

.active {
  z-index: 2 !important;
}

.inactive {
  z-index: 1 !important;
}

 .close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  .tooltipStyle{
      font-size: "16px";
      background-color: #E3F2FF;    
      color: #000;
  }

  .css-1jwtk0j-MuiTooltip-tooltip {
    max-width: 500px !important;
    margin-top: 0px !important;
    margin-left: 2.5rem !important;
  }

  .sections__container {
    margin: 0 auto;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  
  .sections__container__a {
    width: 35%;
    height: 100%;
  }
  
  .sections__container__b {
    width: 15%;
    height: 100%;
  }
  
  .sections__container__c {
    width: 50%;
    height: 100%;
    word-wrap:break-word;
  }

.cust-card {
    min-height: 60rem !important;
}
 @media (min-width: 768px) and (max-width: 819px) {
  .cust-card {
      min-height: 64em!important;
  }
 }

 @media (min-width: 820px) and (max-width: 1180px) {
  .cust-card {
      min-height: 64em !important;
  }
 }
  

  @media (min-width: 375px) and (max-width: 414px) {
    .toolInfo {
      width: 30px !important;
      height: auto !important;
      margin-left: 1rem;
      z-index: 2 !important;
  }
  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
    
    .css-1a24lfo-MuiTooltip-tooltip {
      margin-top: 0rem !important;
      max-width: 300px !important;
      margin-left: -3rem !important;
    }

    .sections__container__a {
      width: 80%;
      height: 100%;
    }
    
    .sections__container__b {
      width: 20%;
      height: 100%;
    }
    
    .sections__container__c {
      width: 100%;
      height: 100%;
    }
    
  }  
  
 

  @media (min-width: 415px) and (max-width: 768px) {
    .toolInfo {
        width: 30px !important;
        height: auto !important;
        margin-left: 2rem;
        z-index: 2 !important;
    }
    .css-1a24lfo-MuiTooltip-tooltip {
      margin-top: 0rem !important;
      max-width: 500px !important;
      margin-left: -4rem !important;
    }

    .sections__container__a {
      width: 80%;
      height: 100%;
    }
    
    .sections__container__b {
      width: 20%;
      height: 100%;
    }
    
    .sections__container__c {
      width: 100%;
      height: 100%;
    }
    
  }  
  
  @media (min-width: 769px) and (max-width: 820px) {
    .toolInfo {
        width: 30px !important;
        height: auto !important;
        margin-left: 2rem;
        z-index: 2 !important;
    }
    .css-1a24lfo-MuiTooltip-tooltip {
      margin-top: 0rem !important;
      max-width: 300px !important;
      margin-left: -12rem !important;
    }

    .sections__container__a {
      width: 80%;
      height: 100%;
    }
    
    .sections__container__b {
      width: 20%;
      height: 100%;
    }
    
    .sections__container__c {
      width: 100%;
      height: 100%;
    }
  
  }

  
  

  
